import getApi from '../api';

function add(skecth) {
  skecth = skecth || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .post('/sketches', skecth)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function find(filters) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get('/sketches/', { params: filters })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function update(id, sketch) {
  sketch = sketch || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .put(`/sketches/${id}`, sketch)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findId(id) {
  id = id || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/sketches/${id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function remove(id) {
  id = id || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .delete(`/sketches/${id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default {
  add,
  find,
  update,
  findId,
  remove
};
