import getApi from '../api';

function findAll() {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get('/access')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findUserAccess(user) {
  user = user || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/users/${user.id}/access`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default {
  findAll,
  findUserAccess
};
