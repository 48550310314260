import getApi from '../api';

function trigger(trigger) {
  trigger = trigger || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .post('/triggers', trigger)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default {
  trigger
};
