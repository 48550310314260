<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <v-card class="box card-cambio">
        <h1>Dados Cadastrais</h1>
        <v-divider class="py-3" />
        <v-row>
          <v-col
            class="py-0"
            cols="12"
            md="2"
          >
            <v-radio-group
              row
              class="py-0"
              v-model="bank.active"
            >
              <v-radio
                :value="true"
                :label="'Ativo'"
              />
              <v-radio
                :value="false"
                :label="'Inativo'"
              />
            </v-radio-group>
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="2"
          >
            <v-autocomplete
              label="Tipos de operação"
              :items="bankTypes"
              item-text="description"
              item-value="id"
              v-model="bank.exchangeType"
              return-object
              clearable
              dense
              outlined
              v-disabled-icon-focus
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="2"
          >
            <CpfOrCnpjField
              is-p-j
              v-model="bank.cnpj"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="3"
          >
            <v-text-field
              outlined
              dense
              label="Razão Social"
              v-model="bank.companyName"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="3"
          >
            <v-text-field
              outlined
              dense
              label="Nome Fantasia"
              v-model="bank.fantasyName"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="12"
          >
            <AddressComponent v-model="bank" />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            offset-md="2"
            md="3"
          >
          <v-autocomplete
              label="Ficha de Registro PF"
              :items="templates"
              item-text="description"
              item-value="id"
              v-model="bank.pfRegisterFormTemplate"
              clearable
              dense
              outlined
              v-disabled-icon-focus
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="3"
          >
            <v-autocomplete
              label="Ficha de Registro PJ"
              :items="templates"
              item-text="description"
              item-value="id"
              v-model="bank.pjRegisterFormTemplate"
              clearable
              dense
              outlined
              v-disabled-icon-focus
            />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// Apis
import apiExchangeBankType from "@/api/exchangeBank/exchange-bank-type-api";
import apiTemplates from "@/api/generic/template-api";

// Models
import ExchangeBankTypeModel from "@/model/exchange-bank-type-model";
import TemplateModel from "@/model/generic/template-model";

// Components
import CpfOrCnpjField from "@/components/comum/CpfOrCnpjField";
import AddressComponent from "@/components/comum/AddressComponent";

// Mixins
import mixinMessage from "@/mixin/mixin-message";

export default {
  name: "BankCover",
  mixins: [ mixinMessage ],
  components: {
    CpfOrCnpjField,
    AddressComponent,
  },
  model: {
    prop: "bankProp",
    event: "onChange",
  },
  props: {
    bankProp: {
      type: Object,
    },
  },
  data() {
    return {
      bank: this.bankProp,
      bankTypes: [],
      templates: [],
    };
  },
  watch: {
    bank() {
      this.$emit("onChange", this.bank);
    },
  },
  mounted() {
    this.getBankTypes();
    this.getTemplateList();
  },
  methods: {
    getBankTypes() {
      apiExchangeBankType
        .findAll()
        .then((response) => {
          this.bankTypes = response.data.map(t => new ExchangeBankTypeModel(t));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
    getTemplateList() {
      apiTemplates
        .findAll()
        .then((response) => {
          this.templates = response.data.map((t) => new TemplateModel(t));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
  }
};
</script>

<style></style>
