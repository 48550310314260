import CustomerAdditionalModel from './customer-additional';
import CustomerAddressModel from './customer-address-model';
import CustomerPartnerShareholderModel from './customer-partner-shareholder-model';
import CustomerDirectorProxyModel from './customer-director-proxy-model';
import CustomerDocumentModel from './customer-document-model';
import CustomerInternationalBankModel from './customer-international-bank-model';
import CustomerNationalBankModel from './customer-national-bank-model';
import CustomerOccupationModel from './customer-ocupation-model';
import CustomerComplianceModel from './customer-compliance-model';
import Workflow from '../../../common/models/Workflow';
import { CUSTOMER_WORKFLOW } from "../../../common/workflows/customersWorkflow";
import IdentificationDocumentTypeModel from './identification-document-type-model';
import CustomerExchangeBankModel from './customer-exchange-bank-model';
import CorrespondentModel from './correspondent-model';
import CustomerSpreadModel from './customer-spread-model';
import CustomerOperationalDocumentModel from './customer-operational-document-model';
import CustomerPaymentAccountModel from './customer-payment-account-model';
import Person from '../../../common/models/Person';


export default class CustomerModel extends Person {
  constructor(obj) {
    obj = obj || {};
    super(obj);

    let partnersShareholders = obj.partnersShareholders?.map(partnersShareholders => new CustomerPartnerShareholderModel(partnersShareholders));
    let directorsProxies = obj.directorsProxies?.map(directorsProxies => new CustomerDirectorProxyModel(directorsProxies));
    let addresses = obj.addresses?.map(addresses => new CustomerAddressModel(addresses));
    let documents = obj.documents?.map(documents => new CustomerDocumentModel(documents));
    let internationalBanks = obj.internationalBanks?.map(internationalBanks => new CustomerInternationalBankModel(internationalBanks));
    let nationalBanks = obj.nationalBanks?.map(nationalBanks => new CustomerNationalBankModel(nationalBanks));
    let exchangeBanks = obj.exchangeBanks?.map(exchangeBank => new CustomerExchangeBankModel(exchangeBank));
    let spreads = obj.spreads?.map(spread => new CustomerSpreadModel(spread));
    let operationalDocuments = obj.operationalDocuments?.map(operationalDocument => new CustomerOperationalDocumentModel(operationalDocument));
    let paymentAccounts = obj.paymentAccounts?.map(paymentAccount => new CustomerPaymentAccountModel(paymentAccount));

    // Starter informations
    this.id = obj.id;
    this.cpfCnpj = obj.cpfCnpj;
    this.name = obj.name;
    this.birthDate = obj.birthDate;
    this.identificationDocumentType = new IdentificationDocumentTypeModel(obj.identificationDocumentType);
    this.documentNumber = obj.documentNumber;
    this.documentIssueDate = obj.documentIssueDate;
    this.documentDueDate = obj.documentDueDate;
    this.state = obj.state;
    this.issuingAgency = obj.issuingAgency;
    this.sex = obj.sex;
    this.registerStatus = obj.registerStatus?.id ? new Workflow(obj.registerStatus) : new Workflow(CUSTOMER_WORKFLOW.CADASTRO_ENVIADO);
    this.birthCountry = obj.birthCountry;
    this.cellphone = obj.cellphone;
    this.landline = obj.landline;
    this.email = obj.email;
    this.secondEmail = obj.secondEmail;
    this.birthplace = obj.birthplace;
    this.isBrazilian = obj.isBrazilian;
    this.fatherName = obj.fatherName;
    this.motherName = obj.motherName;
    this.companyName = obj.companyName;
    this.fantasyName = obj.fantasyName;
    this.usPerson = obj.usPerson;
    this.website = obj.website;
    this.documents = documents?.length ? documents : [];
    this.addresses = addresses?.length ? addresses : [];
    this.additional = new CustomerAdditionalModel(obj.additional);
    this.residentialAddress = new CustomerAddressModel();
    this.deliveryAddress = new CustomerAddressModel();
    this.professionalAddress = new CustomerAddressModel();
    this.internationalBanks = internationalBanks?.length ? internationalBanks : [];
    this.nationalBanks = nationalBanks?.length ? nationalBanks : [];
    this.occupation = new CustomerOccupationModel(obj.occupation);
    this.partnersShareholders = partnersShareholders?.length ? partnersShareholders : [];
    this.directorsProxies = directorsProxies?.length ? directorsProxies : [];
    this.compliance = new CustomerComplianceModel(obj.compliance);
    this.sendExternalLink = false;
    this.exchangeBanks = exchangeBanks?.length ? exchangeBanks : [];
    this.correspondent = new CorrespondentModel(obj.correspondent);
    this.spreads = spreads?.length ? spreads : [];
    this.operationalDocuments = operationalDocuments?.length ? operationalDocuments : [];
    this.paymentAccounts = paymentAccounts?.length ? paymentAccounts : [];
    this.externalId = obj.externalId;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;

    this.customerNameOrCompanyName = (this.companyName || '' + this.name || '').trim();

    this.setPersonType();
  }
}