import getApi from '../api';

function findByAgent(agentId) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/agents/${agentId}/commission-accounts`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findRelated(agentId) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/agents/${agentId}/commission-accounts/related`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default {
  findByAgent,
  findRelated
};
